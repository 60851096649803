var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.uploadType === 'FILE' ? _c('div', [_c('p', {
    staticClass: "text-2xl font-bold mb-6 text-left"
  }, [_vm._v("Upload New File")]), _c('div', {
    staticClass: "modal-body-drag-area mb-2",
    on: {
      "dragover": _vm.dragover,
      "dragleave": _vm.dragleave,
      "drop": _vm.drop
    }
  }, [!_vm.isUploading ? _c('div', [_c('input', {
    ref: "file",
    attrs: {
      "type": "file",
      "name": "file-input",
      "id": "assetsFieldHandle",
      "multiple": "multiple",
      "accept": "application/pdf,video/mp4,image/png,image/webp"
    },
    on: {
      "change": _vm.onChange
    }
  }), _c('div', {
    staticClass: "upload-icon"
  }, [_c('upload', {
    attrs: {
      "width": "486",
      "height": "48",
      "color": "#f4ae56"
    }
  }), _c('div', {
    staticClass: "upload-icon-text"
  }, [_vm._v("Drag & Drop File")]), _c('div', [_vm._v("Or")])], 1), _c('label', {
    staticClass: "block cursor-pointer",
    attrs: {
      "for": "assetsFieldHandle"
    }
  }, [!_vm.isReadyToSend ? _c('span', [_vm._v("Select File")]) : _c('span', [_vm._v("Change File")])])]) : _vm._e(), _c('div', {
    staticClass: "text-left flex justify-center items-center w-full flex-col"
  }, _vm._l(_vm.selectedFile, function (file, fileIndex) {
    return _c('div', {
      key: 'file-' + fileIndex,
      staticClass: "flex gap-2 items-center mb-2",
      class: {
        'text-system-error': file.size > _vm.maxFileSize
      }
    }, [_c('div', [file.isLoading ? _c('div', {
      staticClass: "py-2.5 px-5 mr-2 text-sm font-medium w-full bg-gray-100 rounded-lg border border-gray-200 text-yellow inline-flex items-center"
    }, [_c('div', {
      staticClass: "mx-auto"
    }, [_c('svg', {
      staticClass: "inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600",
      attrs: {
        "aria-hidden": "true",
        "role": "status",
        "viewBox": "0 0 100 101",
        "fill": "none",
        "xmlns": "http://www.w3.org/2000/svg"
      }
    }, [_c('path', {
      attrs: {
        "d": "M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z",
        "fill": "currentColor"
      }
    }), _c('path', {
      attrs: {
        "d": "M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z",
        "fill": "#f4ae56"
      }
    })]), _vm._v(" Upload " + _vm._s(file.name) + " ")])]) : _c('div', {
      staticClass: "text-sm text-neutral-400 flex gap-2 items-center py-2 px-3 rounded relative",
      class: [_vm.selectBackgroundColor(file.status)]
    }, [_c('Attach', {
      attrs: {
        "color": file.size > _vm.maxFileSize ? '#EB5757' : '#ADADAD'
      }
    }), _c('span', {
      staticClass: "overflow-ellipsis w-80 whitespace-nowrap overflow-hidden",
      class: {
        'text-system-error': file.size > _vm.maxFileSize
      }
    }, [_vm._v(_vm._s(file.name))]), _c('span', {
      staticClass: "text-sm text-neutral-400 inline-block w-20 text-right",
      class: {
        'text-system-error': file.size > _vm.maxFileSize
      }
    }, [_vm._v(" (" + _vm._s(_vm.bytesToSize(file.size)) + ") ")]), _c('span', {
      staticClass: "inline-block absolute -right-2 -top-2 cursor-pointer bg-white rounded-full border-system-error",
      on: {
        "click": function click($event) {
          return _vm.remove(fileIndex);
        }
      }
    }, [_c('Close', {
      attrs: {
        "color": "system-error",
        "width": "16",
        "height": "16"
      }
    })], 1)], 1), _c('div', {
      staticClass: "text-xs",
      class: [_vm.isFileFailed(file.isFailed)]
    }, [_vm._v(_vm._s(file.message))])])]);
  }), 0)]), _c('div', {
    staticClass: "notes text-left"
  }, [_vm._v("* You can choose up to 5 files.")]), _c('div', {
    staticClass: "notes text-left"
  }, [_vm._v("* Maximum size is 800MB for each file.")]), _c('div', {
    staticClass: "notes text-left"
  }, [_vm._v("* File format must be .pdf or .mp4")]), _vm.error ? _c('div', {
    staticClass: "text-system-error mt-2 text-sm"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e(), _c('div', {
    staticClass: "upload-submit"
  }, [_c('button', {
    class: {
      disabled: !_vm.isReadyToSend
    },
    attrs: {
      "disabled": !_vm.isReadyToSend || _vm.isUploading
    },
    on: {
      "click": _vm.submit
    }
  }, [!_vm.isUploading ? [_vm._v("Upload")] : _c('LoadingDot')], 2)])]) : _c('div', [_c('h1', {
    staticClass: "text-2xl text-left font-bold"
  }, [_vm._v("Add New Folder")]), _c('div', {
    staticClass: "mt-5 mb-5"
  }, [_c('TextField', {
    attrs: {
      "label": "Folder Name",
      "maxlength": "200",
      "type": "text",
      "borderEnabled": "",
      "placeholder": "Type a folder name"
    },
    model: {
      value: _vm.newFolderName,
      callback: function callback($$v) {
        _vm.newFolderName = $$v;
      },
      expression: "newFolderName"
    }
  }), _c('p', {
    staticClass: "text-right text-sm pr-1 pt-1"
  }, [_vm._v(_vm._s(_vm.folderNameLength) + " / 200")])], 1), _c('div', {
    staticClass: "flex justify-end gap-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "additionalClass": "hover:opacity-50 border-2 py-3",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.closeAndRefetch();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Create",
      "type": "primary"
    },
    on: {
      "action": function action($event) {
        return _vm.submit();
      }
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }