<template>
  <div>
    <div v-if="uploadType === 'FILE'">
      <p class="text-2xl font-bold mb-6 text-left">Upload New File</p>
      <div class="modal-body-drag-area mb-2" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <div v-if="!isUploading">
          <input type="file" name="file-input" id="assetsFieldHandle" @change="onChange" multiple="multiple" ref="file" accept="application/pdf,video/mp4,image/png,image/webp" />
          <div class="upload-icon">
            <upload width="486" height="48" color="#f4ae56" />
            <div class="upload-icon-text">Drag & Drop File</div>
            <div>Or</div>
          </div>
          <label for="assetsFieldHandle" class="block cursor-pointer">
            <span v-if="!isReadyToSend">Select File</span>
            <span v-else>Change File</span>
          </label>
        </div>
        <div class="text-left flex justify-center items-center w-full flex-col">
          <div class="flex gap-2 items-center mb-2" v-for="(file, fileIndex) in selectedFile" :key="'file-' + fileIndex" :class="{ 'text-system-error': file.size > maxFileSize }">
            <div>
              <div v-if="file.isLoading" class="py-2.5 px-5 mr-2 text-sm font-medium w-full bg-gray-100 rounded-lg border border-gray-200 text-yellow inline-flex items-center">
                <div class="mx-auto">
                  <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#f4ae56"
                    />
                  </svg>
                  Upload {{ file.name }}
                </div>
              </div>
              <div v-else class="text-sm text-neutral-400 flex gap-2 items-center py-2 px-3 rounded relative" :class="[selectBackgroundColor(file.status)]">
                <Attach :color="file.size > maxFileSize ? '#EB5757' : '#ADADAD'" />
                <span class="overflow-ellipsis w-80 whitespace-nowrap overflow-hidden" :class="{ 'text-system-error': file.size > maxFileSize }">{{ file.name }}</span>
                <span class="text-sm text-neutral-400 inline-block w-20 text-right" :class="{ 'text-system-error': file.size > maxFileSize }"> ({{ bytesToSize(file.size) }}) </span>
                <span @click="remove(fileIndex)" class="inline-block absolute -right-2 -top-2 cursor-pointer bg-white rounded-full border-system-error"
                  ><Close color="system-error" width="16" height="16"
                /></span>
              </div>
              <div class="text-xs" :class="[isFileFailed(file.isFailed)]">{{ file.message }}</div>
            </div>
            <!-- <div class="">
            <div class="bar relative">
              <span class="font-medium text-smallest text-yellow-primary transition-all inline-block absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">{{
                `${progressReal[fileIndex]}%`
              }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
                <circle cx="16" cy="16" r="15.9155" class="progress-bar__background" />

                <circle
                  cx="16"
                  cy="16"
                  r="15.9155"
                  class="progress-bar__progress js-progress-bar"
                  :style="{
                    'stroke-dashoffset': progressPercentage[fileIndex]
                  }"
                />
              </svg>
            </div>
          </div> -->
          </div>
        </div>
      </div>
      <div class="notes text-left">* You can choose up to 5 files.</div>
      <div class="notes text-left">* Maximum size is 800MB for each file.</div>
      <div class="notes text-left">* File format must be .pdf or .mp4</div>
      <div class="text-system-error mt-2 text-sm" v-if="error">{{ error }}</div>

      <div class="upload-submit">
        <button :disabled="!isReadyToSend || isUploading" :class="{ disabled: !isReadyToSend }" @click="submit">
          <template v-if="!isUploading">Upload</template>
          <LoadingDot v-else />
        </button>
      </div>
    </div>
    <div v-else>
      <h1 class="text-2xl text-left font-bold">Add New Folder</h1>
      <div class="mt-5 mb-5">
        <TextField label="Folder Name" maxlength="200" type="text" borderEnabled placeholder="Type a folder name" v-model="newFolderName" />
        <p class="text-right text-sm pr-1 pt-1">{{ folderNameLength }} / 200</p>
      </div>
      <div class="flex justify-end gap-5">
        <Button buttonText="Cancel" additionalClass="hover:opacity-50 border-2 py-3" type="tertiary" @action="closeAndRefetch()" />
        <Button buttonText="Create" type="primary" @action="submit()" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import Upload from '@/components/Icons/Upload'
import LoadingDot from '@/components/Illustration/LoadingDot'
import { showVueToast } from '@/utils'
export default {
  components: {
    Upload,
    LoadingDot,
    Attach: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Attach'),
    Close: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Close'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button')
  },
  props: {
    uploadType: {
      type: String,
      default: 'FILE'
    },
    activeId: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    selectedFile: {},
    fileStatus: [],
    error: null,
    isReadyToSend: false,
    isUploading: false,
    progressPercentage: [],
    progressReal: [],
    maxFiles: 5,
    maxFileSize: 800 * 1024 * 1024,
    newFolderName: '',
    totalFile: 0,
    totalFileSuccess: 0,
    totalFileError: 0,
    count: 0
  }),
  watch: {
    selectedFile(val) {
      if (val.length < 1) {
        this.isReadyToSend = false
      }
    },
    newFolderName(val) {
      let removeSlash = val.replace(/[/]/g, '')
      this.newFolderName = removeSlash
    }
  },
  computed: {
    clientId() {
      return localStorage.getItem('client')
    },
    folderNameLength() {
      return this.newFolderName.length
    }
  },
  methods: {
    ...mapActions('gallery', ['UPLOAD_FILE', 'UPLOAD_FILE_V3']),
    close(event) {
      this.$emit('close')
    },
    selectBackgroundColor(status) {
      if (status === 'NORMAL') return 'bg-grey'
      if (status === 'FAILED') return 'bg-red-10'
      return 'bg-green-10'
    },
    isFileFailed(isFailed) {
      if (isFailed) return 'text-red'
      return 'text-green'
    },
    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]})`
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
    },
    addData() {
      return new Promise((resolve) => {
        const bucket = Array.from(this.$refs.file.files)
        this.totalFile = bucket.length
        bucket.map((file, index) => {
          bucket[index].message = ''
          bucket[index].isLoading = false
          bucket[index].status = 'NORMAL'
        })
        resolve(bucket)
      })
    },
    async onChange() {
      this.selectedFile = await this.addData()
      this.error = null
      if (this.selectedFile.length > this.maxFiles) {
        this.error = `You've exceeded the maximum number of ${this.maxFiles} files!`
        this.selectedFile = null
        return
      }
      let violateFileSize = false
      for (let i = 0; i < this.selectedFile.length; i++) {
        this.progressPercentage[i] = 100
        this.progressReal[i] = 0
        if (this.selectedFile[i].size > this.maxFileSize) {
          violateFileSize = true
          this.error = `There is file(s) that exceeded the maximum size of 800MB. Please re-select the files.`
        }
      }
      if (violateFileSize) {
        return
      }
      this.isReadyToSend = true
    },
    refetch() {
      this.$emit('refetch')
    },
    closeAndRefetch() {
      this.$emit('closeAndRefetch')
    },
    submit() {
      this.uploadType === 'FILE' ? this.uploadFile() : this.uploadFolder()
    },
    uploadFolder() {
      const filePayload = new FormData()
      filePayload.append('file', null)
      filePayload.append('source_name', this.newFolderName)
      filePayload.append('current_path_id', this.activeId.toString())
      filePayload.append('customer_id', this.clientId.toString())
      filePayload.append('type', this.uploadType)
      this.UPLOAD_FILE_V3({
        payloads: filePayload
      }).then((res) => {
        if (res.status === 200) {
          showVueToast('Folder is created successfully!', 'success', 2000)
          this.closeAndRefetch()
          return
        }
        if (res.code === 422) {
          return showVueToast(res.errors.error, 'error', 2000)
        }
        showVueToast('Failed to create new folder!', 'error', 2000)
      })
    },
    uploadFile() {
      this.count = 0
      this.totalFileSuccess = 0
      this.totalFileError = 0
      this.isUploading = true
      const customerId = this.clientId
      for (let i = 0; i < this.selectedFile.length; i++) {
        this.count++
        this.selectedFile[i].message = ''
        const filePayload = new FormData()
        filePayload.append('file', this.selectedFile[i])
        filePayload.append('source_name', this.selectedFile[i].name)
        filePayload.append('current_path_id', this.activeId.toString())
        filePayload.append('customer_id', customerId.toString())
        filePayload.append('type', this.uploadType)
        this.uploadFileAPI(filePayload, i).then(() => {
          this.selectedFile[i].isLoading = false
          if (this.count === this.totalFile) this.afterUpload()
        })
      }
    },
    uploadFileAPI(payloads, index) {
      this.selectedFile[index].isLoading = true
      return new Promise((resolve) => {
        const filedIndex = index
        this.UPLOAD_FILE_V3({
          payloads
        }).then((res) => {
          if (res.status === 200) {
            this.totalFileSuccess++
            this.selectedFile[filedIndex].status = 'SUCCESS'
            this.selectedFile[filedIndex].isFailed = false
            this.selectedFile[filedIndex].message = 'File uploaded successfully!'
            setTimeout(() => {
              resolve(false)
            }, 1000)
          } else if (res.code === 422) {
            this.totalFileError++
            this.selectedFile[filedIndex].message = `${this.selectedFile[filedIndex].name} is already exist!`
            this.selectedFile[filedIndex].status = 'FAILED'
            this.selectedFile[filedIndex].isFailed = true
            setTimeout(() => {
              resolve(false)
            }, 1000)
          } else {
            this.totalFileError++
            this.selectedFile[filedIndex].message = 'File failed to upload!'
            this.selectedFile[filedIndex].status = 'FAILED'
            this.selectedFile[filedIndex].isFailed = true
            setTimeout(() => {
              resolve(false)
            }, 1000)
          }
        })
      })
    },
    afterUpload() {
      if (this.totalFileError > 0) {
        this.isUploading = false
        if (this.totalFileSuccess > 0) this.refetch()
        return
      }
      showVueToast('File is uploaded successfully!', 'success', 2000)
      this.closeAndRefetch()
      this.isUploading = false
    },
    uploadProgress(value, fileIndex) {
      this.progressPercentage[fileIndex] = 100 - value
      this.progressReal[fileIndex] = value
      this.$forceUpdate()
    },
    remove(i) {
      this.selectedFile.splice(i, 1)
      this.error = null
      this.checkError()
    },
    checkError() {
      let isError = false
      for (let i = 0; i < this.selectedFile.length; i++) {
        if (this.selectedFile[i].size > this.maxFileSize) {
          isError = true
          this.error = `There is file(s) that exceeded the maximum size of 800MB. Please re-select the files.`
        }
      }
      if (isError) this.isReadyToSend = false
      else this.isReadyToSend = true
    },
    dragover(event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('uploading')) {
        event.currentTarget.classList.add('uploading')
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('uploading')
    },
    drop(event) {
      this.error = null
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange()
      event.currentTarget.classList.remove('uploading')
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-body {
  position: relative;

  &-drag {
    &-area {
      border: 2px dashed #ccc;
      border-radius: 6px;
      padding: 20px;
      display: flex;
      min-height: 200px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      transition: all 200ms linear;
      input {
        position: absolute;
        opacity: 0;
        overflow: hidden;
        height: 1px;
        width: 1px;
      }
      label {
        cursor: pointer;
        margin-top: 15px;
        span {
          background-color: #f4ae56;
          border: none;
          outline: none;
          color: #fff;
          border-radius: 3px;
          padding: 8px 16px;
          font-size: 12px;
          transition: all 100ms linear;
          display: inline-block;
          margin-bottom: 5px;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .upload-icon {
        &-text {
          margin-bottom: 5px;
        }
      }
    }
  }
}
.uploading {
  background-color: #eee;
}
.upload-submit {
  text-align: center;
  margin-top: 30px;
  button {
    background-color: #f4ae56;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 3px;
    padding: 8px 40px;
    margin: 0;
    transition: all 100ms linear;
    width: 100%;
    &:hover {
      opacity: 0.8;
    }
    &.disabled {
      background-color: #ccc;
      cursor: not-allowed;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.error {
  color: red;
  margin: 10px 0px;
  min-height: 20px;
}
.upload-files {
  &-name {
    display: block;
    color: #999;
  }
  &-size {
    font-size: 12px;
    color: #999;
    display: inline-block;
    margin-left: 5px;
  }
}
.notes {
  color: #999;
  font-size: 12px;
}
.progress {
  background-color: #f4ae56;
}
.rounded-lg {
  border-radius: 8px !important;
}

$progress-bar-stroke-width: 1.8;
$progress-bar-size: 36px;

.bar svg {
  height: $progress-bar-size;
  transform: rotate(-90deg);
  width: $progress-bar-size;
}

.progress-bar__background {
  fill: none;
  stroke: #eee;
  stroke-width: $progress-bar-stroke-width;
}

.progress-bar__progress {
  fill: none;
  stroke: #eee;
  stroke: #f4ae56;
  stroke-dasharray: 100 100;
  stroke-dashoffset: 100;
  stroke-linecap: round;
  stroke-width: $progress-bar-stroke-width;
  transition: stroke-dashoffset 100ms ease-in-out;
}
.border-system-error {
  border-width: 1px !important;
}
</style>
